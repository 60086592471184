import { DataGridPremium, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-premium";
import { collection, query, where, orderBy, limit, updateDoc, doc, onSnapshot } from 'firebase/firestore'
import { analytics, db } from "../../firebase-config";
import { Box, Button, Stack, Paper } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { getBlob, getStorage, ref as storageRef } from "firebase/storage";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import printJS from "print-js";
import PrintIcon from "@mui/icons-material/Print";
import { useSnackbar } from "notistack";
import PDFMerger from "pdf-merger-js/browser";
import FetchCutHistory from "../../components/FetchCutHistory";
import { PrintingForm } from "../../components/PrintingForm";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArticleIcon from '@mui/icons-material/Article';

const rows = [];


export function ListPage(props) {
  const apiRef = useGridApiRef();
  let navigate = useNavigate()
  const user = props.user
  const { enqueueSnackbar } = useSnackbar()
  const [selectionModel, setSelectionModel] = useState([]);
  const [tableData, setTableData] = useState([...rows])
  const [workOrdersForPrinting, setWorkOrdersForPrinting] = useState([])

  const [sortModel, setSortModel] = useState([
    {
      field: "created",
      sort: "desc",
    },
  ])

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const buildInternalUrl = (stairType, id) => {
    // Example of building a URL for a route within your app
    // Replace ':id' with actual value, and adjust the path as per your routing configuration
    if (typeof id === "number") {
      id = id.toString()
    }
    const path =  generatePath(`/${stairType}/:id`, { id: id });
    console.log(path)
    return path
  };

  const onClickUrl = (url) => openInNewTab(url)

  // Utility function to fetch work orders with optional conditions
  const fetchOrders = useCallback((userId, limitResults, additionalConditions = []) => {
    const baseConditions = [
      where("uid", "==", userId),
      orderBy("created", "desc"),
      ...additionalConditions,
    ];

    if (limitResults) {
      baseConditions.push(limit(limitResults));
    }

    const q = query(collection(db, "work_orders"), ...baseConditions);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempRows = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          clientName: docData.clientName,
          address: docData.address,
          isQuote: docData.isQuote || false,
          workOrder: docData.workOrder,
          stairType: docData.stairType,
          modelName: docData.modelName,
          dueDate: docData.dueDate.toDate().toISOString().split("T")[0],
          created: docData.created.toDate().toISOString().split("T")[0],
          orderItems: docData.orderItems.length,
          inProduction: docData.inProduction,
          stairsBuilt: docData.stairsBuilt,
          stairsShipped: docData.stairsShipped,
          lumberOrder: docData.lumberOrder,
          additionalLumberOrder: docData.additionalLumberOrder || {},
        }
      });

      setTableData(tempRows);
      if (limitResults) logEvent(analytics, "Clicked: View All Orders");
    });

    return unsubscribe;
  }, [])

  // Use in useEffect with stairsShipped condition
  useEffect(() => {
    const unsubscribe = fetchOrders(user.uid, null, [where("stairsShipped", "==", false)]);
    return () => unsubscribe();
  }, [fetchOrders, user.uid]);

  // Use in handleViewAllOrders without stairsShipped condition
  const handleViewAllOrders = (limitResults) => {
    fetchOrders(user.uid, limitResults);
    setSortModel([{ field: "created", sort: "desc" }]);
  };

  const handleRowClick = async (params) => {
    navigate(`/${params.row.stairType}/${params.id}`)
  }

  const fetchPDF = async (id, onSuccess) => {
    try {
      const storage = getStorage();
      const fileRef = storageRef(storage, `complete-${id}.pdf`);
      const blob = await getBlob(fileRef);
      onSuccess(blob);
    } catch (e) {
      console.log(e);
      if (e.code === "storage/object-not-found") {
        enqueueSnackbar("No PDF created for this item.", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error.", { variant: "error" });
      }
    }
  };

  const toggleField = async (row, field) => {
    const docRefSet = doc(db, "work_orders", row.id);
    try {
      await updateDoc(docRefSet, { [field]: !row[field] });
    } catch (e) {
      console.error("Error:", JSON.stringify(e, null, 2));
    }
  };


  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Files",
      width: 200,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key="action_open_in_new_window"
            onClick={() => onClickUrl(buildInternalUrl(row.stairType, row.id))}
            icon={<OpenInNewIcon />}
            label="Open In New Window"
            color="inherit"
            sx={{ mx: .5 }}
          />,
          <GridActionsCellItem
            key="action_preview"
            onClick={() => fetchPDF(row.id, (blob) => window.open(URL.createObjectURL(blob), "_blank"))}
            icon={<ArticleIcon />}
            label="Launch"
            color="inherit"
            sx={{ mx: .5 }}
          />,
          <GridActionsCellItem
            key="action_download"
            onClick={async () => {
              try {
                fetchPDF(row.id, (blob) => {
                  const blobUrl = URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.href = blobUrl
                  const pdfTitle = row.workOrder.replace(/[^a-zA-Z0-9 ]/g, '')
                  link.download = `WorkOrder-${pdfTitle}.pdf`
                  link.click()
                })
              } catch (e) {
                console.error("Error: ", JSON.stringify(e, null, 2))
                enqueueSnackbar("Unknown error.", { variant: "error" })
              }
            }
            }
            icon={< FileDownloadOutlinedIcon />}
            label="Download"
            color="inherit"
            sx={{ mx: .5 }}
          />,
          < GridActionsCellItem
            key="action_print"
            onClick={async () => {
              try {
                fetchPDF(row.id, (blob) => {
                  const blobUrl = URL.createObjectURL(blob)
                  printJS(blobUrl)
                })
              } catch (e) {
                console.error("Error: ", JSON.stringify(e, null, 2))
                enqueueSnackbar("Unknown error.", { variant: "error" })

              }
            }}
            icon={< PrintIcon />}
            label="Print"
            color="inherit"
            sx={{ mx: .5 }}
          />

        ];
      },
    },
    { field: 'clientName', headerName: 'Client', width: 100, editable: false },
    { field: 'address', headerName: 'Address', width: 150, editable: false },
    { field: 'workOrder', headerName: 'Work Order', width: 120, editable: false },
    { field: 'stairType', headerName: 'Type', width: 90, editable: false },
    { field: 'modelName', headerName: 'Model', width: 90, editable: false },
    { field: 'created', headerName: 'Created', width: 125, editable: false },
    { field: 'dueDate', headerName: 'Due Date', width: 100, editable: false },
    { field: 'orderItems', headerName: 'Items', width: 60, editable: false, align: "center" },
    {
      field: 'inProduction',
      headerName: 'In Production',
      width: 115,
      type: "actions",
      align: "center",
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={row.inProduction ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          onClick={() => toggleField(row, 'inProduction')}
          label="Toggle Checkbox"
        />,
      ],
    },
    {
      field: 'stairsBuilt',
      headerName: 'Built',
      width: 80,
      type: "actions",
      align: "center",
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={row.stairsBuilt ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          onClick={() => toggleField(row, 'stairsBuilt')}
          label="Toggle Checkbox"
        />,
      ],
    },
    {
      field: 'stairsShipped',
      headerName: 'Shipped',
      width: 80,
      type: "actions",
      align: "center",
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={row.stairsShipped ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          onClick={() => toggleField(row, 'stairsShipped')}
          label="Toggle Checkbox"
        />,
      ],
    },
  ];


  return (
    <Box>
      <Box
        sx={{
          maxWidth: "1485px",
          mx: "auto"
        }}

      >
        <Stack
          direction={"row"}
          spacing={4}
          justifyContent={"left"}
          sx={{ mt: 2 }}
        >
          <Paper>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"left"}
              sx={{ m: 1 }}
            >
              <Button
                onClick={() => handleViewAllOrders(250)}
                variant={"contained"}
              >
                View Last 250 Orders
              </Button>
              <Button
                onClick={() => handleViewAllOrders()}
                variant={"contained"}
              >
                View All Orders
              </Button>
            </Stack>
          </Paper>
          <Paper>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"left"}
              sx={{ m: 1 }}
            >

              <Button onClick={async () => {
                if (selectionModel.length) {
                  try {
                    const toProcess = selectionModel
                    const blobsToMerge = []
                    const storage = getStorage()
                    for (const item of toProcess) {
                      const fileRef = storageRef(storage, `complete-${item}.pdf`)
                      const blob = await getBlob(fileRef)
                      blobsToMerge.push(blob)
                    }
                    const merger = new PDFMerger();
                    for (const blob of blobsToMerge) {
                      await merger.add(blob)
                    }
                    const mergedPdf = await merger.saveAsBlob();
                    const blobUrl = URL.createObjectURL(mergedPdf)
                    printJS(blobUrl)
                  } catch (e) {
                    if (e.code === "storage/object-not-found") {
                      enqueueSnackbar("Error: PDF not created for one of the selected files.", { variant: "error" })
                    } else {
                      enqueueSnackbar("Unknown Error while preparing files", { variant: "error" })
                    }
                    console.log(e)
                  }
                }

              }}
                disabled={selectionModel.length < 2}
                variant={"contained"}
              >
                Print+
              </Button>
              <Button onClick={async () => {
                if (selectionModel.length) {
                  try {
                    const toProcess = selectionModel
                    const blobsToMerge = []
                    const storage = getStorage()
                    for (const item of toProcess) {
                      const fileRef = storageRef(storage, `complete-${item}.pdf`)
                      const blob = await getBlob(fileRef)
                      blobsToMerge.push(blob)
                    }
                    const merger = new PDFMerger();
                    for (const blob of blobsToMerge) {
                      await merger.add(blob)
                    }
                    const mergedPdf = await merger.saveAsBlob();
                    const blobUrl = URL.createObjectURL(mergedPdf)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    const date = new Date();
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    let minute = date.getMinutes()
                    let hour = date.getHours()
                    link.download = `${selectionModel.length}-MergedFiles-${year}-${month}-${day}-${hour}h_${minute}m.pdf`
                    link.click()
                  } catch (e) {
                    if (e.code === "storage/object-not-found") {
                      enqueueSnackbar("Error: PDF not created for one of the selected files.", { variant: "error" })
                    } else {
                      enqueueSnackbar("Unknown Error while preparing files", { variant: "error" })
                    }
                    console.log(e)
                  }
                }

              }}
                disabled={selectionModel.length < 2}
                variant={"contained"}
              >
                Download+
              </Button>
            </Stack>
          </Paper>
          <Paper>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"left"}
              sx={{ m: 1 }}
            >

              <Button onClick={async () => {
                if (selectionModel.length) {
                  try {
                    const printAllOnOrder = tableData.filter((row) => selectionModel.includes(row.id))
                    const printAllOnOrderPrepared = printAllOnOrder.map(row => { return { lumberSelected: "all", row } })
                    setWorkOrdersForPrinting(prev => {
                      const idsToRemove = []
                      const updated = prev.map(item => {
                        if (selectionModel.includes(item.row.id)) {
                          idsToRemove.push(item.row.id)
                          return { lumberSelected: "all", row: item.row }
                        }
                        return item
                      })

                      const remainingOrders = printAllOnOrderPrepared.filter(item => !idsToRemove.includes(item.row.id))
                      return [...updated, ...remainingOrders]
                    })
                    // handleAddToLumberQueue()
                    // handleDownloadClick();
                  } catch (e) {
                    enqueueSnackbar("Error attempting to send documentIds for querying");
                    console.log(e)
                  }
                }
              }}
                disabled={selectionModel.length < 1}
                variant={"contained"}
              >
                + All Lumber
              </Button>

              <Button onClick={async () => {
                if (selectionModel.length) {
                  try {
                    const printAdditionalOnOrder = tableData.filter((row) => selectionModel.includes(row.id) && Object.keys(row.additionalLumberOrder).length)
                    const printAdditionalOnOrderPrepared = printAdditionalOnOrder.map(row => { return { lumberSelected: "additional", row } })
                    setWorkOrdersForPrinting(prev => {
                      const idsToRemove = []
                      const updated = prev.map(item => {
                        if (selectionModel.includes(item.row.id)) {
                          idsToRemove.push(item.row.id)
                          return { lumberSelected: "additional", row: item.row }
                        }
                        return item
                      })

                      const remainingOrders = printAdditionalOnOrderPrepared.filter(item => !idsToRemove.includes(item.row.id))
                      return [...updated, ...remainingOrders]
                    })
                    // handleAddToLumberQueue()
                    // handleDownloadClick();
                  } catch (e) {
                    enqueueSnackbar("Error attempting to send documentIds for querying");
                    console.log(e)
                  }
                }
              }}
                disabled={selectionModel.length < 1}
                variant={"contained"}
              >
                + Additional Lumber
              </Button>
              <Button onClick={() => setWorkOrdersForPrinting([])}
                variant={"contained"}
                disabled={workOrdersForPrinting.length === 0}
              >
                Clear Lumber Order Queue
              </Button>
            </Stack>
          </Paper>


        </Stack>

      </Box>
      <Box sx={{ height: 600, width: "1480px", mx: "auto", my: "15px" }}>
        <DataGridPremium
          apiRef={apiRef}
          editMode="row"
          rows={tableData}
          columns={columns}
          onRowClick={handleRowClick}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          checkboxSelection
          selectionModel={selectionModel}
          getRowClassName={(params) =>
            params.row.isQuote ? 'quote-row' : ''
          }
          sx={{
            '& .quote-row': {
              backgroundColor: '#ffebee',
            },
            // Optional: Add hover effect to maintain readability
            '& .quote-row:hover': {
              backgroundColor: '#ffcdd2',
            },
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          density={"compact"}
        />
      </Box>

      <Box sx={{ width: "1480px", mx: "auto", my: "15px" }}>
        <PrintingForm
          workOrders={workOrdersForPrinting}
          visibility="visible"
        />
      </Box>
      {false ? <FetchCutHistory /> : <></>}
    </Box>
  )
}

