/**
 * This accept 1 to n orders and print out the combined
 * lumber order amount.
 * For the css styles to be applied with PrintJs. The css file must be included in the public folder.
 * I've included it here as well, in case later this component wants to be displayed.
 * PrintJS doesn't recognize / read mui styles. So I converted mui styling to the PrintForm.css page.
 */
import * as React from 'react';
import { useState, useEffect } from 'react';
import print from 'print-js'
import './printForm.css';
import { Button } from "@mui/material";

function formatLength(length) {
  if (/^\d+$/.test(length)) {
    return `${length / 12} ft`;
  }
  return length;
}


// custom sort function for Map
function customSortKey(item) {
  // Define the desired sorting order
  const customOrder = [
    "2x12", "2x10", "2x8", "2x6", "2x4", "1x4",
    "plywood 5/8\"", "plywood 3/4\"", "plywood 1\"", "plywood 5/8\" PT",
    "2x12 PT Brown", "2x10 PT Brown", "2x8 PT Brown", "2x6 PT Brown", "2x4 PT Brown",
    "2x12: Additional", "2x10: Additional", "2x8: Additional", "2x6: Additional", "2x4: Additional", "1x4: Additional",
    "plywood 5/8\": Additional", "plywood 3/4\": Additional", "plywood 1\": Additional", "plywood 5/8\" PT: Additional",
    "2x12 PT Brown: Additional", "2x10 PT Brown: Additional", "2x8 PT Brown: Additional", "2x6 PT Brown: Additional", "2x4 PT Brown: Additional"
  ];  return customOrder.indexOf(item) !== -1 ? customOrder.indexOf(item) : customOrder.length;
}


function combinePartials(lumberArray){
  console.log(lumberArray)

  const lumberPlywoodRoundedUp = lumberArray.map(lumber => {
    console.log(typeof lumber[0])
    if (lumber[0].includes("plywood")){
      if ("48 x 48" in lumber[1]){
        const fullSize = lumber[1]["48 x 48"] / 2
        const fullSizeRoundUp = Math.ceil(fullSize)
        
        if (!("48 x 96" in lumber[1])){
          return [lumber[0], {"48 x 96": fullSizeRoundUp}]
        } else {
          const total = fullSizeRoundUp + lumber[1]["48 x 96"]
          return [lumber[0], {"48 x 96": total}] 
        }
      }
    }
    return lumber
  })
  return lumberPlywoodRoundedUp
}

// Function to sort the map
function sortLumberArray(lumberArray) {
  // Convert map to array for sorting
  // const lumberArray = Array.from(lumberMap);

  // Sort the array based on the custom key and secondary sort by keys of the inner object
  lumberArray.sort((a, b) => {
    const typeComparison = customSortKey(a[0]) - customSortKey(b[0]);
    if (typeComparison !== 0) {
      return typeComparison;
    }
    // Secondary sort by keys of the inner object
    const keysA = Object.keys(a[1]).sort((x, y) => parseInt(x) - parseInt(y));
    const keysB = Object.keys(b[1]).sort((x, y) => parseInt(x) - parseInt(y));
    return keysA[0] - keysB[0];
  });

  // Convert back to Map
  return lumberArray;
  // return new Map(lumberArray);
}

export const PrintingForm = ({ workOrders, visibility }) => {
  const [orderTotal, setOrderTotal] = useState(new Map());

  // Calculate the Order Total - only when workOrders changes
  useEffect(() => {
    // Use a new Map to clear old state
    const newOrderTotal = new Map();
    workOrders.forEach(orderInstruction => {
      const order = orderInstruction.row
      if (orderInstruction.lumberSelected === "all") {
        Object.entries(order.lumberOrder).forEach(([material, details]) => {
          Object.entries(details).forEach(([length, count]) => {
            if (newOrderTotal.has(material)) {
              const lengths = newOrderTotal.get(material);
              // Add or update the length and count
              lengths[length] = (lengths[length] || 0) + count;
            } else {
              // The material doesn't exist - add it's length and count.
              newOrderTotal.set(material, { [length]: count });
            }
          });
        });
      }
      if (Object.keys(order.additionalLumberOrder).length) {
        Object.entries(order.additionalLumberOrder).forEach(([material, details]) => {
          Object.entries(details).forEach(([length, count]) => {
            if (newOrderTotal.has(material)) {
              const lengths = newOrderTotal.get(material);
              // Add or update the length and count
              lengths[length] = (lengths[length] || 0) + count;
            } else {
              // The material doesn't exist - add it's length and count.
              newOrderTotal.set(material, { [length]: count });
            }
          });
        });
      }
    });

    setOrderTotal(newOrderTotal);
  }, [workOrders]);

  return (
    workOrders.length ? <>
      <Button
        variant={"contained"}
        // type="button"
        className="print-btn"
        onClick={() =>
          print({
            printable: "printJS-form",
            type: "html",
            css: [
              "/printForm.css" // Ensure this file is in the public directory
            ],
            scanStyles: false
          })
        }>
        Print Orders
      </Button>
      <div id="printJS-form" style={{ visibility }}>
        {/* Header Section */}
        <div className="header">
          <h1>Sawback Craft Co</h1>
          <p>
            2850 107 Ave SE #102,<br />
            Calgary, AB T2Z 3Z4<br />
          </p>
        </div>
        <div className="order-info order-total">
          <h2 className="section-title">Order Total</h2>
          <table>
            <thead>
              <tr>
                <th>Material</th>
                <th>Length</th>
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>
              {
                combinePartials(sortLumberArray(Array.from(orderTotal))).map(([material, lengths]) => (
                  <React.Fragment key={material}>
                    {
                      Object.entries(lengths).map(([length, count]) => (
                        <tr key={`${material}-${length}`}>
                          <td>{material}</td>
                          <td>{formatLength(length)}</td>
                          <td>{count}</td>
                        </tr>
                      ))
                    }
                  </React.Fragment>
                ))
              }
            </tbody>
          </table>
        </div>
        {
          workOrders.map(({ row: order, lumberSelected }, index) => {
            return (
              <React.Fragment key={index}>
                <div className="client-info"  >
                  <h2 className="section-title">Order</h2>
                  <ul >
                    <li><strong>Address: </strong>{order.address}</li>
                    <li><strong>Product: </strong>{order.stairType}</li>
                    <li><strong>Work Order: </strong>{order.workOrder}</li>
                    <li><strong>Date: </strong>09-01-2024</li>
                  </ul>
                </div>
                <div className="order-info">
                  <table>
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th>Length</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        lumberSelected === "all"
                          ?
                          [

                            ...sortLumberArray(Object.entries(order.lumberOrder)).map(([material, details]) => (
                              Object.entries(details).map(([length, count], i) => (
                                <tr key={`${material}-${length}-${i}`}>
                                  <td>{material}</td>
                                  <td>{formatLength(length)}</td>
                                  <td>{count}</td>
                                </tr>
                              ))
                            )),
                            ...sortLumberArray(Object.entries(order.additionalLumberOrder)).map(([material, details]) => (
                              Object.entries(details).map(([length, count], i) => (
                                <tr key={`${material}-${length}-${i}`}>
                                  <td>{material}: Additional</td>
                                  <td>{formatLength(length)}</td>
                                  <td>{count}</td>
                                </tr>
                              ))
                            ))
                          ]

                          :
                          sortLumberArray(Object.entries(order.additionalLumberOrder)).map(([material, details]) => (
                            Object.entries(details).map(([length, count], i) => (
                              <tr key={`${material}-${length}-${i}`}>
                                <td>{material}: Additional</td>
                                <td>{formatLength(length)}</td>
                                <td>{count}</td>
                              </tr>
                            ))
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )
          })}
      </div>
    </> : 
    <></>
  );
}