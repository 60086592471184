import { Box, gridClasses, Typography, Button, FormControl, InputLabel, Select, MenuItem, Stack } from "@mui/material";
import { DataGridPremium, GridToolbarContainer, GridToolbarExport, GridActionsCellItem } from "@mui/x-data-grid-premium";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useMemo, useState } from "react";


function AdditionalLumberOrderDisplay({ additionalLumberOrder, setAdditionalLumberOrder, ...restProps }) {
  const [selectedLumberId, setSelectedLumberId] = useState('');
  const lumberOptions = useMemo(() => [
    // 1x4 SPF lengths in inches
    { id: "1x4-168", type: "1x4", length: 168 },

    // 2x4 SPF lengths in inches
    { id: "2x4-96", type: "2x4", length: 96 },
    
    // 2x6 SPF lengths in inches
    { id: "2x6-96", type: "2x6", length: 96 },
    { id: "2x6-120", type: "2x6", length: 120 },
    { id: "2x6-144", type: "2x6", length: 144 },
    { id: "2x6-168", type: "2x6", length: 168 },
    { id: "2x6-192", type: "2x6", length: 192 },

    // 2x8 SPF lengths in inches
    { id: "2x8-96", type: "2x8", length: 96 },
    { id: "2x8-120", type: "2x8", length: 120 },
    { id: "2x8-144", type: "2x8", length: 144 },
    { id: "2x8-168", type: "2x8", length: 168 },
    { id: "2x8-192", type: "2x8", length: 192 },
    { id: "2x8-216", type: "2x8", length: 216 },
    { id: "2x8-240", type: "2x8", length: 240 },

    // 2x10 SPF lengths in inches
    { id: "2x10-96", type: "2x10", length: 96 },
    { id: "2x10-120", type: "2x10", length: 120 },
    { id: "2x10-144", type: "2x10", length: 144 },
    { id: "2x10-168", type: "2x10", length: 168 },
    { id: "2x10-192", type: "2x10", length: 192 },
    { id: "2x10-216", type: "2x10", length: 216 },
    { id: "2x10-240", type: "2x10", length: 240 },

    // 2x12 SPF lengths in inches
    { id: "2x12-96", type: "2x12", length: 96 },
    { id: "2x12-120", type: "2x12", length: 120 },
    { id: "2x12-144", type: "2x12", length: 144 },
    { id: "2x12-168", type: "2x12", length: 168 },
    { id: "2x12-192", type: "2x12", length: 192 },
    { id: "2x12-216", type: "2x12", length: 216 },
    { id: "2x12-240", type: "2x12", length: 240 },

    // Brown
    { id: "2x4-96", type: "2x4 PT Brown", length: 96 },

    { id: "2x6-96", type: "2x6 PT Brown", length: 96 },
    { id: "2x6-120", type: "2x6 PT Brown", length: 120 },
    { id: "2x6-144", type: "2x6 PT Brown", length: 144 },
    { id: "2x6-168", type: "2x6 PT Brown", length: 168 },
    { id: "2x6-192", type: "2x6 PT Brown", length: 192 },

    // Brown
    { id: "2x8-96", type: "2x8 PT Brown", length: 96 },
    { id: "2x8-120", type: "2x8 PT Brown", length: 120 },
    { id: "2x8-144", type: "2x8 PT Brown", length: 144 },
    { id: "2x8-168", type: "2x8 PT Brown", length: 168 },
    { id: "2x8-192", type: "2x8 PT Brown", length: 192 },
    { id: "2x8-216", type: "2x8 PT Brown", length: 216 },
    { id: "2x8-240", type: "2x8 PT Brown", length: 240 },

    // Brown 2x10 
    { id: "2x10-96", type: "2x10 PT Brown", length: 96 },
    { id: "2x10-120", type: "2x10 PT Brown", length: 120 },
    { id: "2x10-144", type: "2x10 PT Brown", length: 144 },
    { id: "2x10-168", type: "2x10 PT Brown", length: 168 },
    { id: "2x10-192", type: "2x10 PT Brown", length: 192 },
    { id: "2x10-216", type: "2x10 PT Brown", length: 216 },
    { id: "2x10-240", type: "2x10 PT Brown", length: 240 },

    // Brown 2x12 
    { id: "2x12-96", type: "2x12 PT Brown", length: 96 },
    { id: "2x12-120", type: "2x12 PT Brown", length: 120 },
    { id: "2x12-144", type: "2x12 PT Brown", length: 144 },
    { id: "2x12-168", type: "2x12 PT Brown", length: 168 },
    { id: "2x12-192", type: "2x12 PT Brown", length: 192 },
    { id: "2x12-216", type: "2x12 PT Brown", length: 216 },
    { id: "2x12-240", type: "2x12 PT Brown", length: 240 },

    // Plywood options
    { id: 'plywood 5/8": 48x96', type: 'plywood 5/8"', length: "48x96" },
    { id: 'plywood 5/8": 48x48', type: 'plywood 5/8"', length: "48x48" },
    { id: 'plywood 3/4": 48x96', type: 'plywood 3/4"', length: "48x96" },
    { id: 'plywood 3/4": 48x48', type: 'plywood 3/4"', length: "48x48" },
    { id: 'plywood 1": 48x96', type: 'plywood 1"', length: "48x96" },     
    { id: 'plywood 1": 48x48', type: 'plywood 1"', length: "48x48" },     
    { id: 'plywood 5/8" PT: 48x96', type: 'plywood 5/8" PT', length: "48x96" },
    { id: 'plywood 5/8" PT: 48x48', type: 'plywood 5/8" PT', length: "48x48" },
  ], []);

  const selectedLumber = useMemo(() =>
    lumberOptions.find(option => option.id === selectedLumberId),
    [lumberOptions, selectedLumberId], selectedLumberId
  );

  const handleChange = (event) => {
    setSelectedLumberId(event.target.value)
  };
  const handleClick = () => {
    if (selectedLumber) {
      // updateLumberOrder(restProps.orderId, selectedLumber, action);
      setAdditionalLumberOrder(prev => {
        let newOrder = { ...prev };
        if (newOrder[selectedLumber.type]) {
          if (newOrder[selectedLumber.type][selectedLumber.length]) {
            newOrder[selectedLumber.type][selectedLumber.length] += 1;
          } else {
            newOrder[selectedLumber.type][selectedLumber.length] = 1;
          }
        } else {
          newOrder[selectedLumber.type] = { [selectedLumber.length]: 1 };
        }
        return newOrder;
      });
    };
  }
  const rows = useMemo(() => {
    if (additionalLumberOrder) {
      let result = [];
      // let idCounter = 1;  // Initialize the counter
      for (let material in additionalLumberOrder) {
        let materialObject = additionalLumberOrder[material];

        // For each material, loop over its lengths
        for (let length in materialObject) {
          let count = materialObject[length];

          // Push each material, length, and count as an object into the results array
          result.push({
            // id: idCounter,  // Assign the current counter value as id
            id: material + "-" + length,
            type: material,
            length: length,
            count: count
          });

          // idCounter++;  // Increment the counter
        }
      }

      return result
    } else {
      return []
    }

  }, [additionalLumberOrder])

  const [sortModel, setSortModel] = useState([
    {
      field: "type",
      sort: "asc",
    },
    {
      field: "length",
      sort: "asc",
    },
  ])


  const actionsColumn = {
    field: "actions", type: "actions", headerName: "Actions", width: 100, getActions: ({ row }) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => setAdditionalLumberOrder(prev => {
            let newOrder = { ...prev };
            delete newOrder[row.type][row.length];
            if (Object.keys(newOrder[row.type]).length === 0) {
              delete newOrder[row.type];
            }
            return newOrder;
          })}
          color="inherit"
        />,
      ]
    }
  };

  const columns = [
    actionsColumn,
    { field: 'type', headerName: 'Material', width: 200, editable: false, type: "string" },
    { field: 'length', headerName: 'Length', width: 125, editable: false, type: "string" },
    { field: 'count', headerName: 'Count', width: 100, editable: false, type: "number" },
  ];


  function EditToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            textAlign: "center",
            width: "100%",
            pt: 2,
            pb: 1,
            pl: 1,
          }}
        >
          {restProps.clientName} || {restProps.workOrder} || {restProps.address} || {restProps.dueDate} || {restProps.modelName}
        </Box>
        <Box sx={{ displayPrint: "none" }}>
          <GridToolbarExport />
        </Box>
      </GridToolbarContainer>
    );
  }


  return (
    <Box>
      <Typography variant="h4" gutterBottom component="div" mt={2} textAlign="center">
        Additional Lumber Order
      </Typography>
      <Box sx={{ width: "800px", mx: "auto", my: "25px" }}>
        <Stack direction="row" spacing={2} sx={{ mb: "15px" }}>
          <FormControl sx={{ width: "250px" }}>
            <InputLabel id="lumber-select-label">Lumber</InputLabel>
            <Select
              labelId="lumber-select-label"
              id="lumber-select"
              value={selectedLumberId}
              label="Lumber"
              onChange={handleChange}
            >
              {lumberOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.type} ${option.length}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            onClick={handleClick}
            disabled={!selectedLumberId}
          >
            Add to Order
          </Button>
        </Stack>
        <DataGridPremium
          autoHeight
          editMode="row"
          rows={rows}
          columns={columns}
          density={"compact"}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: EditToolbar,
          }}
        />
      </Box>

    </Box >
  )
};

export default AdditionalLumberOrderDisplay;