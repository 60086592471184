import './App.css';
import {useState} from "react";
import {onAuthStateChanged, signOut} from "firebase/auth"
import {auth} from "./firebase-config"
import {Navigate, Outlet, Route, Routes, useLocation, Link as RouterLink} from "react-router-dom";
import {AppBar, Box, Button, CircularProgress, Link, Stack, Toolbar} from "@mui/material";
import {LoginPage} from './pages/LoginPage'
import {RegisterPage} from './pages/RegisterPage'
import {ListPage} from "./pages/protected/ListPage";
import {CreateOrder} from "./pages/protected/CreateOrder";
import {LicenseInfo} from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "9980ef928f0a134bdc01c7a01e650c52Tz05NjgyNyxFPTE3NTYwNTIyMDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);


function App() {
    const [user, setUser] = useState({})

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
        if (currentUser) {
            localStorage.setItem("signedIn", "true")
        } else {
            localStorage.removeItem("signedIn")
        }
    })

    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route element={<Layout user={user}/>}>
                    <Route path="/" element={<PublicPage/>}/>
                    <Route
                        path="/list"
                        element={
                            <RequireAuth>
                                <ListPage user={user}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/veranda"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"veranda"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/veranda/:order"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"veranda"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/deck"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"deck"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/deck/:order"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"deck"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/garage"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"garage"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/garage/:order"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"garage"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/interior"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"interior"}/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/interior/:order"
                        element={
                            <RequireAuth>
                                <CreateOrder stairType={"interior"}/>
                            </RequireAuth>
                        }
                    />
                    <Route path="*" element={<PublicPage/>}/>
                </Route>
            </Routes>
        </div>
    );
}

function Layout({user}) {
    const logout = async () => {
        await signOut(auth)
    }

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar
                position="fixed"
            >
                <Toolbar sx={{
                    // display: 'flex',
                    backgroundColor: "#4C71CF",
                }}>
                    <Box
                        sx={{
                            width: "1490px",
                            mx: "auto",
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                        >
                            <Link component={RouterLink} to="/list" color="#fff" underline="none">Orders</Link>
                            <Link component={RouterLink} to="/veranda" color="#fff" underline="none">Veranda</Link>
                            <Link component={RouterLink} to="/deck" color="#fff" underline="none">Deck</Link>
                            <Link component={RouterLink} to="/garage" color="#fff" underline="none">Garage</Link>
                            <Link component={RouterLink} to="/interior" color="#fff" underline="none">Interior</Link>
                        </Stack>
                    </Box>
                </Toolbar>

            </AppBar>
            <Box
                component="main"
                sx={{
                    minHeight: "100vh",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "space-between",
                    flexGrow: 1, p: 0
                }}
            >
                <Box>
                    <Toolbar/>
                    <Outlet/>
                </Box>
                <Box>
                    {user?.email ?
                        <Box textAlign="center" px={3}>
                            <h4>User Logged in: {user.email} </h4><Button onClick={logout}>Sign Out</Button>
                        </Box>
                        : null}
                </Box>
            </Box>
        </Box>
    );
}


function RequireAuth({children}) {
    const [render, setRender] = useState(0)
    let location = useLocation()
    const signedIn = localStorage.getItem("signedIn")
    if (!auth.currentUser && signedIn) {
        if (render === 0) {
            setRender(1) // rerender to update localstorage
        }
        return <Box textAlign="center"><CircularProgress/></Box>
    } else if (auth.currentUser && signedIn) {
        return children
    } else if (!auth.currentUser && !signedIn) {
        return <Navigate to="/login" state={{from: location}}/>
    }
    return <Navigate to="/login" state={{from: location}}/>
}

function PublicPage() {
    const [render, setRender] = useState(0)
    const signedIn = localStorage.getItem("signedIn")
    if (!auth.currentUser && signedIn) {
        if (render === 0) {
            setRender(1) // rerender to update localstorage
        }
        return <Box textAlign="center"><CircularProgress/></Box>
    } else if (auth.currentUser && signedIn) {
        return <Navigate to="/list"/>
    } else if (!auth.currentUser && !signedIn) {
        return <Navigate to="/login"/>
    }
    return <Navigate to="/login"/>
}


export default App;
