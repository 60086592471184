function getTotalHeight(row) {
  if (row.rises && row.rise) {
    return row.rises * row.rise
  }
  return null
}

function getNumberOfStringers(row, stairType) {
  if (stairType === "garage" || stairType === "interior") {
    return row.width ? 2 : ""
  }
  if (row.width) {
    if (row.width <= 40) {
      return 2
    } else if (row.width <= 50) {
      return 3
    } else if (row.width <= 72) {
      return 4
    } else if (row.width > 72) {
      return 5
    }
  }
  return ""
}


function getStringerLength(row) {
  return row.rises
}

function getMiddleRiserHeight(row, stairType, isFlush) {
  const additional = (stairType === "garage" || stairType === "interior") && !isFlush ? .25 : 0

  if (row.totalHeight && row.rises) {
    return (row.totalHeight / row.rises) + additional
  }
  return null
}

function getBottomRiserHeight(row, stairType) {
  if (stairType === "veranda") {
    return row.middleRiserHeight ? row.middleRiserHeight - 1.5 : null
  } else if (stairType === "garage" || stairType === "interior") {
    return row.middleRiserHeight ? row.middleRiserHeight - 1 : null
  }
  return null
}

function getTopRiserHeight(row) {
  if (row.nosing === null) row.nosing = "Yes"
  return row.nosing.match(/No/gm) ? 13.75 : 14.75
}

function getTreadsCount2x6(row) {
  // when you are over 10", you need 3 2x6's to cover the run. Anything under that can be covered by 2
  if (row.run > 10) {
    return (row.rises - 1) * 3
  }
  return (row.rises - 1) * 2
}

function getTreadsCount2x6Veranda(row) {
  // when you are over 10", you need 3 2x6's to cover the run. Anything under that can be covered by 2
  if (row.run > 10) {
    return (row.rises - 1) * 1
  }
  return (row.rises - 1) * 2
}

function getTreadsCount2x8Veranda(row) {
  // when you are over 10", you need 1 2x6's to cover the run.
  if (row.run > 10) {
    return (row.rises - 1) * 1
  }
  return 0
}




function getTreadsCount(row) {
  return row.rises ? row.rises - 1 : null
}

// function getBlockingCount2x4x8(row, stairType) {
//   if (stairType === "deck") return Math.floor(row.rises / 2)

//   if (row.rises <= 3) {
//     return 0
//   } else if (row.rises <= 7) {
//     return 1
//   } else if (row.rises <= 20) {
//     return 2
//   } else if (row.rises <= 30) {
//     return 3
//   } else if (row.rises > 30) {
//     return 4
//   } else {
//     return 0
//   }
// }

function removeZeroValues(item) {
  return item === 0 ? null : item
}

function getStairLabourPrice(row, stairType) {
  // Pricing is also input manually into functions > index
  // Any changes here need to be reflected there
  if (stairType === "deck") return row.rises * 57
  if (stairType === "veranda") return row.rises * 85
  if (stairType === "garage") return row.rises * 45
  if (stairType === "interior") {
    const numberOfStringers = row.numberOfStringersManual ? row.numberOfStringersManual : row.numberOfStringers
    const isProfileLeft = !!(row.profileLeft !== "No" || row.profileLeftManual)
    const isProfileRight = !!(row.profileRight !== "No" || row.profileRightManual)
    const priceRises = numberOfStringers > 2 ? row.rises * 49 : row.rises * 45
    const priceProfileLeft = isProfileLeft ? 20 : 0
    const priceProfileRight = isProfileRight ? 20 : 0
    return priceRises + priceProfileLeft + priceProfileRight
  }
}

function getGarageLandingPrice() {
  return 120
}

function getInteriorWinderLabourPrice(row) {
  if (row.itemType === "Winder - 2 Step") {
    return 232
  } else if (row.itemType === "Winder - 3 Step") {
    return 298
  }
  return 0
}

function getVerandaStairCutList(row, isRoutered, isCapped) {
  let cutList = []
  // if capped, just use cap value, otherwise, if routered, use routered value
  const adjustedWidthForRouterAndCap = isCapped ? row.width - 3 :
    isRoutered ? row.width - 2.375 : row.width

  if (isCapped) isRoutered = false

  const blockingToOrder = {
    material: '2x4 PT Brown',
    description: `Blocking - ${row.stairSet}`,
    length: row.width > 48 ? 96 : 48,
    count: 1,
    displayOnCutList: false,
  }

  const treadsCutList = {
    material: "2x6 PT Brown",
    description: `Treads - ${row.stairSet}`,
    length: adjustedWidthForRouterAndCap,
    count: row.treadsCount2x6,
    displayOnCutList: true
  }

  const treads2x8CutList = {
    material: "2x8 PT Brown",
    description: `Treads - ${row.stairSet}`,
    length: adjustedWidthForRouterAndCap,
    count: row.treadsCount2x8,
    displayOnCutList: true
  }

  const bottomRiseCutList = {
    material: 'plywood 5/8" PT',
    description: `Bottom Rise - ${row.stairSet}`,
    length: row.bottomRiserHeight,
    width: adjustedWidthForRouterAndCap,
    count: 1,
    displayOnCutList: true,
  }

  const middleRiseCount = row.rises > 2 ? row.rises - 2 : null

  const middleRiseCutList = {
    material: 'plywood 5/8" PT',
    description: `Middle Rise - ${row.stairSet}`,
    length: row.middleRiserHeight,
    width: adjustedWidthForRouterAndCap,
    count: middleRiseCount,
    displayOnCutList: true,
  }

  const topRiseCutList = {
    material: 'plywood 5/8" PT',
    description: `Top Rise - ${row.stairSet}`,
    length: row.topRiserHeight,
    width: row.width,
    count: 1,
    displayOnCutList: true,
  }

  let lengthStringer

  if (row.stringerLengthManual) {
    lengthStringer = row.stringerLengthManual * 12 // in the form it is set to ft
  } else {
    if (isRoutered) {
      lengthStringer = (row.stringerLength * 12) + 12
    } else {
      lengthStringer = row.stringerLength * 12
    }
  }
  let countNumberOfStringers;
  if (row.numberOfStringersManual) {
    countNumberOfStringers = row.numberOfStringersManual
  } else {
    countNumberOfStringers = row.numberOfStringers
  }


  const stringerCutList = {
    material: "2x12 PT Brown",
    description: "Stringer Cut List", //
    length: lengthStringer,
    count: countNumberOfStringers,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x12 = {
    material: "2x12 PT Brown",
    description: "Stringer Cut List Cap", //
    length: lengthStringer + 12,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x4 = {
    material: "2x4 PT Brown",
    description: "Stringer Cut List Cap", //
    length: lengthStringer,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  cutList.push(blockingToOrder)
  if (row.treadsCount2x8) cutList.push(treads2x8CutList)
  if (isCapped) cutList.push(stringerCutListCapped2x12, stringerCutListCapped2x4)
  cutList.push(treadsCutList)
  cutList.push(bottomRiseCutList)
  if (middleRiseCount) cutList.push(middleRiseCutList) // check if there are any middle rises
  cutList.push(topRiseCutList)
  cutList.push(stringerCutList)
  return cutList
}

function getDeckStairCutList(row, isRoutered, isCapped) {
  let cutList = []

  // if capped, just use cap value, otherwise, if routered, use routered value
  const adjustedWidthForRouterAndCap = isCapped ? row.width - 3 :
    isRoutered ? row.width - 2.375 : row.width

  if (isCapped) isRoutered = false

  const blockingToOrder = {
    material: '2x4 PT Brown',
    description: `Blocking - ${row.stairSet}`,
    length: row.width > 48 ? 96 : 48,
    count: 1,
    displayOnCutList: false,

  }

  const treadsCutList = {
    material: '2x6 PT Brown',
    description: `Treads - ${row.stairSet}`,
    length: adjustedWidthForRouterAndCap,
    count: row.treadsCount2x6,
    displayOnCutList: true,
  }

  // const topRiseCutList = {
  //   material: 'plywood 5/8" PT',
  //   description: `Top Rise - ${row.stairSet}`,
  //   length: row.topRiserHeight,
  //   width: row.width,
  //   count: 1,
  //   displayOnCutList: true,
  // }

  let lengthStringer

  if (row.stringerLengthManual) {
    lengthStringer = row.stringerLengthManual * 12 // in the form it is set to ft
  } else {
    if (isRoutered) {
      lengthStringer = (row.stringerLength * 12) + 12
    } else {
      lengthStringer = row.stringerLength * 12
    }
  }
  let countNumberOfStringers;
  if (row.numberOfStringersManual) {
    countNumberOfStringers = row.numberOfStringersManual
  } else {
    countNumberOfStringers = row.numberOfStringers
  }

  const stringerCutList = {
    material: "2x12 PT Brown",
    description: "Stringer Cut List", //
    length: lengthStringer,
    count: countNumberOfStringers,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x12 = {
    material: "2x12 PT Brown",
    description: "Stringer Cut List Cap", //
    length: lengthStringer + 12,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x4 = {
    material: "2x4 PT Brown",
    description: "Stringer Cut List Cap", //
    length: lengthStringer,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }
  if (isCapped) cutList.push(stringerCutListCapped2x12, stringerCutListCapped2x4)

  cutList.push(treadsCutList)
  // cutList.push(topRiseCutList)
  cutList.push(stringerCutList)
  cutList.push(blockingToOrder)
  console.log(cutList)
  return cutList
}

function getDeckLandingCutList(row) {
  if (row.width <= 48) {
    return [
      {
        material: "2x6 PT Brown",
        description: `Landing Materials - ${row.stairSet}`,
        length: 144,
        count: 6,
        displayOnCutList: false,
      }
    ]
  }
  if (row.width > 48 && row.width <= 60) {
    return [
      {
        material: "2x6 PT Brown",
        description: `Landing Materials - ${row.stairSet}`,
        length: 144,
        count: 2,
        displayOnCutList: false,
      },
      {
        material: "2x6 PT Brown",
        description: `Landing Materials - ${row.stairSet}`,
        length: 120,
        count: 6,
        displayOnCutList: false,
      }
    ]
  }
  if (row.width > 60 && row.width <= 72) {
    return [
      {
        material: "2x6 PT Brown",
        description: `Landing Materials - ${row.stairSet}`,
        length: 144,
        count: 8,
        displayOnCutList: true,
      },
    ]
  }
}

function getGarageStairCutList(row, isFlush, isRoutered, isCapped) {

  let cutList = []

  const lengthTreads = isFlush ? row.run : row.run + 1
  // if capped, just use cap value, otherwise, if routered, use routered value
  const adjustedWidthForRouterAndCap = isCapped ? row.width - 3 :
    isRoutered ? row.width - 2.375 : row.width

  if (isCapped) isRoutered = false

  
  const blockingToOrder = {
    material: '2x4',
    description: `Blocking - ${row.stairSet}`,
    length: row.width > 48 ? 96 : 48,
    count: 1,
    displayOnCutList: false,
  }

  const treadsCutList = {
    material: 'plywood 1"',
    description: `Treads - ${row.stairSet}`,
    length: lengthTreads,
    width: adjustedWidthForRouterAndCap,
    count: row.treadsCount,
    displayOnCutList: true,
  }

  const bottomRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Bottom Rise - ${row.stairSet}`,
    length: row.bottomRiserHeight,
    width: adjustedWidthForRouterAndCap,
    count: 1,
    displayOnCutList: true,
  }


  const middleRiseCount = row.rises > 2 ? row.rises - 2 : null

  const middleRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Middle Rise - ${row.stairSet}`,
    length: row.middleRiserHeight,
    width: adjustedWidthForRouterAndCap,
    count: middleRiseCount,
    displayOnCutList: true,
  }

  const topRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Top Rise - ${row.stairSet}`,
    length: row.topRiserHeight,
    width: row.width,
    count: 1,
    displayOnCutList: true,
  }

  let lengthStringer

  if (row.stringerLengthManual) {
    lengthStringer = row.stringerLengthManual * 12 // in the form it is set to ft
  } else {
    if (isRoutered) {
      lengthStringer = (row.stringerLength * 12) + 12
    } else {
      lengthStringer = row.stringerLength * 12
    }
  }

  let countNumberOfStringers;
  if (row.numberOfStringersManual) {
    countNumberOfStringers = row.numberOfStringersManual
  } else {
    countNumberOfStringers = row.numberOfStringers
  }

  const stringerCutList = {
    material: "2x12",
    description: "Stringer Cut List", //
    length: lengthStringer,
    count: countNumberOfStringers,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x12 = {
    material: "2x12",
    description: "Stringer Cut List Cap", //
    length: lengthStringer + 12,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x4 = {
    material: "2x4",
    description: "Stringer Cut List Cap", //
    length: lengthStringer,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }
  if (isCapped) cutList.push(stringerCutListCapped2x12, stringerCutListCapped2x4)
  cutList.push(blockingToOrder)
  cutList.push(treadsCutList)
  cutList.push(bottomRiseCutList)
  if (middleRiseCount) cutList.push(middleRiseCutList) // check if there are any middle rises
  cutList.push(topRiseCutList)
  cutList.push(stringerCutList)
  return cutList
}

function getGarageLandingCutList(row) {
  return [
    {
      material: "2x6",
      description: `Landing Frame - ${row.stairSet}`,
      length: 96,
      count: 4,
      displayOnCutList: false,
    },
    {
      material: 'plywood 5/8"',
      description: `Landing Sheeting - ${row.stairSet}`,
      length: row.depthLanding,
      width: row.width,
      count: 1,
      displayOnCutList: false,
    }
  ]

}

function getInteriorStairCutList(row, isFlush, isRoutered, isCapped) {
  let cutList = []

  // if capped, just use cap value, otherwise, if routered, use routered value
  const adjustedWidthForRouterAndCap = isCapped ? row.width - 3 :
    isRoutered ? row.width - 2.375 : row.width

  if (isCapped) isRoutered = false


  const blockingToOrder = {
    material: '2x4',
    description: `Blocking - ${row.stairSet}`,
    length: row.width > 48 ? 96 : 48,
    count: 1,
    displayOnCutList: false,
  }

  const lengthTreads = isFlush ? row.run : row.run + 1

  let profileLeftRiserWidth = 0
  let profileLeftTreadWidth = 0

  if (row.profileLeftManual) {
    profileLeftRiserWidth = row.profileLeftManual
    profileLeftTreadWidth = row.profileLeftManual + 1
  } else if (row.profileLeft === "1a") {
    profileLeftRiserWidth = 6.5
    profileLeftTreadWidth = 7.5
  } else if (row.profileLeft === "1b") {
    profileLeftRiserWidth = 8.5
    profileLeftTreadWidth = 9.5
  } else if (row.profileLeft === "2") {
    profileLeftRiserWidth = 1.5
    profileLeftTreadWidth = 2.5
  } else if (row.profileLeft === "3") {
    profileLeftRiserWidth = 1.5
    profileLeftTreadWidth = 2.5
  } else if (row.profileLeft === "4") {
    profileLeftRiserWidth = 3.25
    profileLeftTreadWidth = 4.25
  } else if (row.profileLeft === "5") {
    profileLeftRiserWidth = 2.5
    profileLeftTreadWidth = 3.5
  }

  let profileRightRiserWidth = 0
  let profileRightTreadWidth = 0

  if (row.profileRightManual) {
    profileRightRiserWidth = row.profileRightManual
    profileRightTreadWidth = row.profileRightManual + 1
  } else if (row.profileRight === "1a") {
    profileRightRiserWidth = 6.5
    profileRightTreadWidth = 7.5
  } else if (row.profileRight === "1b") {
    profileRightRiserWidth = 8.5
    profileRightTreadWidth = 9.5
  } else if (row.profileRight === "2") {
    profileRightRiserWidth = 1.5
    profileRightTreadWidth = 2.5
  } else if (row.profileRight === "3") {
    profileRightRiserWidth = 1.5
    profileRightTreadWidth = 2.5
  } else if (row.profileRight === "4") {
    profileRightRiserWidth = 3.25
    profileRightTreadWidth = 4.25
  } else if (row.profileRight === "5") {
    profileRightRiserWidth = 2.5
    profileRightTreadWidth = 3.5
  }

  let treadsCutList = {
    material: 'plywood 1"',
    description: `Treads - ${row.stairSet}`,
    length: lengthTreads,
    width: adjustedWidthForRouterAndCap,
    count: row.treadsCount,
    displayOnCutList: true,
  }

  const profiles = profileRightTreadWidth + profileLeftTreadWidth
  let profileTreadsCutList = {
    material: 'plywood 1"',
    description: `Treads - ${row.stairSet}`,
    length: lengthTreads,
    width: adjustedWidthForRouterAndCap + profiles,
    count: 1,
    displayOnCutList: true,
  }

  if (profileLeftRiserWidth || profileRightRiserWidth) {
    treadsCutList.count = treadsCutList.count - 1
  } else {
    profileTreadsCutList.count = 0
  }

  const bottomRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Bottom Rise - ${row.stairSet}`,
    length: row.bottomRiserHeight,
    width: adjustedWidthForRouterAndCap + profileLeftRiserWidth + profileRightRiserWidth,
    count: 1,
    displayOnCutList: true,
  }

  const middleRiseCount = row.rises > 2 ? row.rises - 2 : null

  const middleRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Middle Rise - ${row.stairSet}`,
    length: row.middleRiserHeight,
    width: adjustedWidthForRouterAndCap,
    count: middleRiseCount,
    displayOnCutList: true,
  }

  const topRiseCutList = {
    material: isFlush ? 'plywood 1"' : 'plywood 5/8"',
    description: `Top Rise - ${row.stairSet}`,
    length: row.topRiserHeight,
    width: row.width,
    count: 1,
    displayOnCutList: true,
  }

  let lengthStringer

  if (row.stringerLengthManual) {
    lengthStringer = row.stringerLengthManual * 12 // in the form it is set to ft
  } else {
    if (isRoutered) {
      lengthStringer = (row.stringerLength * 12) + 12
    } else {
      lengthStringer = row.stringerLength * 12
    }
  }

  let countNumberOfStringers;
  if (row.numberOfStringersManual) {
    countNumberOfStringers = row.numberOfStringersManual
  } else {
    countNumberOfStringers = row.numberOfStringers
  }

  const stringerCutList = {
    material: "2x12",
    description: "Stringer Cut List",
    length: lengthStringer,
    count: countNumberOfStringers,
    displayOnCutList: false,
  }
  const stringerCutListCapped2x12 = {
    material: "2x12",
    description: "Stringer Cut List Cap", //
    length: lengthStringer + 12,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }

  const stringerCutListCapped2x4 = {
    material: "2x4",
    description: "Stringer Cut List Cap", //
    length: lengthStringer,
    count: 2,
    displayOnCutList: false, //needs to be calculated for lumber order, but not displayed on cut list
  }
  if (isCapped) cutList.push(stringerCutListCapped2x12, stringerCutListCapped2x4)

  cutList.push(blockingToOrder)
  if (treadsCutList.count > 0) cutList.push(treadsCutList)
  if (profileTreadsCutList.count > 0) cutList.push(profileTreadsCutList)
  cutList.push(bottomRiseCutList)
  if (middleRiseCount) cutList.push(middleRiseCutList) // check if there are any middle rises
  cutList.push(topRiseCutList)
  cutList.push(stringerCutList)
  return cutList
}

function getInteriorWinderCutList(row) {
  if (row.itemType.match(/Winder - 3 Step/gm)) {
    return [
      {
        material: "2x12",
        description: `Winder Frame - ${row.stairSet}`,
        length: 120,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: "2x10",
        description: `Winder Frame - ${row.stairSet}`,
        length: 120,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: "2x8",
        description: `Winder Frame - ${row.stairSet}`,
        length: 60,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: "2x6",
        description: `Winder Frame - ${row.stairSet}`,
        length: 60,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: 'plywood 1"',
        description: `Winder Sheeting - ${row.stairSet}`,
        length: 48,
        width: 48,
        count: 1,
        displayOnCutList: false,
      }
    ]
  }
  if (row.itemType.match(/Winder - 2 Step/gm)) {
    return [
      {
        material: "2x12",
        description: `Winder Frame - ${row.stairSet}`,
        length: 144,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: "2x8",
        description: `Winder Frame - ${row.stairSet}`,
        length: 60,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: "2x6",
        description: `Winder Frame - ${row.stairSet}`,
        length: 60,
        count: 1,
        displayOnCutList: false,
      },
      {
        material: 'plywood 1"',
        description: `Winder Sheeting - ${row.stairSet}`,
        length: 48,
        width: 48,
        count: 1,
        displayOnCutList: false,
      }
    ]
  }
  return []
}

export function handleCalculations(row, stairType, isRoutered, isCapped) {
  // order is important, as certain calculations rely on others.
  const isFlush = row.isFlush
  if (row.itemType === "Stairs") {
    row.numberOfStringersManual = removeZeroValues(row.numberOfStringersManual)
    row.stringerLengthManual = removeZeroValues(row.stringerLengthManual)
    row.totalHeight = getTotalHeight(row)
    row.numberOfStringers = getNumberOfStringers(row, stairType)
    row.stringerLength = getStringerLength(row)

    if (stairType !== "deck") {
      row.middleRiserHeight = getMiddleRiserHeight(row, stairType, isFlush)
      row.bottomRiserHeight = getBottomRiserHeight(row, stairType)
    }

    row.topRiserHeight = getTopRiserHeight(row)

    if (stairType === "veranda") {
      row.treadsCount2x6 = getTreadsCount2x6Veranda(row)
      row.treadsCount2x8 = getTreadsCount2x8Veranda(row)
    } else if (stairType === "deck") {
      row.treadsCount2x6 = getTreadsCount2x6(row)
    } else {
      row.treadsCount = getTreadsCount(row)
    }



    // row.blockingCount2x4x8 = getBlockingCount2x4x8(row, stairType)

    if (stairType === "deck") row.depthLanding = null
    if (stairType === "garage") row.depthLanding = null
    row.price = getStairLabourPrice(row, stairType)

    if (stairType === "veranda") {
      row.cutList = getVerandaStairCutList(row, isRoutered, isCapped)
    } else if (stairType === "deck") {
      row.cutList = getDeckStairCutList(row, isRoutered, isCapped)
    } else if (stairType === "garage") {
      row.cutList = getGarageStairCutList(row, isFlush, isRoutered, isCapped)
    } else if (stairType === "interior") {
      row.cutList = getInteriorStairCutList(row, isFlush, isRoutered, isCapped)
      row.profileLeftManual = row.profileLeftManual ? row.profileLeftManual : null
      row.profileRightManual = row.profileRightManual ? row.profileRightManual : null
    }

  } else if (row.itemType === "Landing" && stairType === "deck") {
    row.numberOfStringersManual = null
    row.stringerLengthManual = null
    row.run = null
    row.rises = null
    row.rise = null
    row.numberOfStringers = null
    row.stringerLength = null
    row.topRiserHeight = null
    row.treadsCount2x6 = null
    // row.blockingCount2x4x8 = null
    row.nosing = ""
    row.totalHeight = null
    row.price = 170
    row.cutList = getDeckLandingCutList(row)
    // delete row.blockingCount2x4x8
  } else if (row.itemType === "Landing" && stairType === "garage") {
    row.numberOfStringersManual = null
    row.stringerLengthManual = null
    row.run = null
    row.rises = null
    row.rise = null
    row.numberOfStringers = null
    row.stringerLength = null
    row.topRiserHeight = null
    row.treadsCount = null
    row.middleRiserHeight = null
    row.bottomRiserHeight = null
    row.nosing = ""
    row.totalHeight = null
    row.price = getGarageLandingPrice()
    row.cutList = getGarageLandingCutList(row)
    // delete row.blockingCount2x4x8
  } else if (row.itemType.includes("Winder") && stairType === "interior") {
    row.width = null
    row.numberOfStringersManual = null
    row.stringerLengthManual = null
    row.run = null
    row.rises = null
    row.rise = null
    row.numberOfStringers = null
    row.stringerLength = null
    row.topRiserHeight = null
    row.treadsCount = null
    row.middleRiserHeight = null
    row.bottomRiserHeight = null
    row.profileLeft = ""
    row.profileRight = ""
    row.totalHeight = null
    row.price = getInteriorWinderLabourPrice(row)
    row.cutList = getInteriorWinderCutList(row)
    row.profileLeftManual = null
    row.profileRightManual = null
    // delete row.blockingCount2x4x8
  }

  return row
}
