import { Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import * as PropTypes from "prop-types";

export function CommonForm(props) {
  return (
    <Box m={2}>
      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item>
          <TextField
            label="Client Name"
            size="small"
            InputLabelProps={{ shrink: true, }}
            value={props.clientName}
            onChange={props.onChangeClientName}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Work Order"
            size="small"
            InputLabelProps={{ shrink: true, }}
            value={props.workOrder}
            onChange={props.onChangeWorkOrder}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Address"
            size="small"
            InputLabelProps={{ shrink: true, }}
            value={props.address}
            onChange={props.onChangeAddress}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Due Date"
            size="small"
            type="date"
            InputLabelProps={{ shrink: true, }}
            value={props.dueDate}
            onChange={props.onChangeDueDate}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Model Name"
            size="small"
            InputLabelProps={{ shrink: true, }}
            value={props.modelName}
            onChange={props.onChangeModelName}
          />
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Is Quote:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.isQuote}
                onChange={props.onChangeIsQuote}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Routered:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.isRoutered}
                onChange={props.onChangeIsRoutered}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Cap:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.isCapped}
                onChange={props.onChangeIsCapped}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="In Production:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.inProduction}
                onChange={props.onChangeInProduction}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Built:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.stairsBuilt}
                onChange={props.onChangeStairsBuilt}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Shipped:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.stairsShipped}
                onChange={props.onChangeStairsShipped}
              />}
            />
          </FormGroup>
        </Grid>

      </Grid>
    </Box>
  )
}

CommonForm.propTypes = {
  clientName: PropTypes.string,
  onChangeClientName: PropTypes.func,
  workOrder: PropTypes.string,
  onChangeWorkOrder: PropTypes.func,
  address: PropTypes.string,
  onChangeAddress: PropTypes.func,
  dueDate: PropTypes.string,
  onChangeDueDate: PropTypes.func,
  modelName: PropTypes.string,
  onChangeModelName: PropTypes.func,
  stairsBuilt: PropTypes.bool,
  onChangeStairsBuilt: PropTypes.func,
  inProduction: PropTypes.bool,
  onChangeInProduction: PropTypes.func,
  isQuote: PropTypes.bool,
  onChangeIsQuote: PropTypes.func,
  stairsShipped: PropTypes.bool,
  onChangeStairsShipped: PropTypes.func,
  stairType: PropTypes.string,
};
